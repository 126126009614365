@font-face {
    font-family: 'Averta';
    src: url('./Averta-Regular/Averta-Regular.woff2') format('woff2'),
    url('./Averta-Regular/Averta-Regular.woff') format('woff'),
    url('./Averta-Regular/Averta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: url('./Averta-Semibold/Averta-Semibold.woff2') format('woff2'),
    url('./Averta-Semibold/Averta-Semibold.woff') format('woff'),
    url('./Averta-Semibold/Averta-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
/*
@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Bold.eot');
    src: url('TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Bold.woff2') format('woff2'),
        url('TTNorms-Bold.woff') format('woff'),
        url('TTNorms-Bold.ttf') format('truetype'),
        url('TTNorms-Bold.svg#TTNorms-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Heavy.eot');
    src: url('TTNorms-Heavy.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Heavy.woff2') format('woff2'),
        url('TTNorms-Heavy.woff') format('woff'),
        url('TTNorms-Heavy.ttf') format('truetype'),
        url('TTNorms-Heavy.svg#TTNorms-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Black.eot');
    src: url('TTNorms-Black.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Black.woff2') format('woff2'),
        url('TTNorms-Black.woff') format('woff'),
        url('TTNorms-Black.ttf') format('truetype'),
        url('TTNorms-Black.svg#TTNorms-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Regular.eot');
    src: url('TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Regular.woff2') format('woff2'),
        url('TTNorms-Regular.woff') format('woff'),
        url('TTNorms-Regular.ttf') format('truetype'),
        url('TTNorms-Regular.svg#TTNorms-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Light.eot');
    src: url('TTNorms-Light.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Light.woff2') format('woff2'),
        url('TTNorms-Light.woff') format('woff'),
        url('TTNorms-Light.ttf') format('truetype'),
        url('TTNorms-Light.svg#TTNorms-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Medium.eot');
    src: url('TTNorms-Medium.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Medium.woff2') format('woff2'),
        url('TTNorms-Medium.woff') format('woff'),
        url('TTNorms-Medium.ttf') format('truetype'),
        url('TTNorms-Medium.svg#TTNorms-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url('TTNorms-Italic.eot');
    src: url('TTNorms-Italic.eot?#iefix') format('embedded-opentype'),
        url('TTNorms-Italic.woff2') format('woff2'),
        url('TTNorms-Italic.woff') format('woff'),
        url('TTNorms-Italic.ttf') format('truetype'),
        url('TTNorms-Italic.svg#TTNorms-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
*/
