@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/_action";
@use "../mixins/background-svg";
@use "../_base/svg";
@use "../_config/colors";


.nav--top-footer-nav {
  width: 100%;
  border: 1px var(--color-white);
  border-style: solid none;
  padding-top: 19.5px;
  padding-bottom: 19.5px;


  .nav__wrap {
    width: 100%;
    display: block;
  }

  .nav__link {
    @extend .font-size-900;
    position: relative;
    color: var(--white);
    width: 100%;
    display: block;

    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      position: absolute;
      content: '';

      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, right, center, no-repeat, auto, 60%, colors.$color-white);
    }
  }


  @include mq.mq($from: largeFooter) {
    border: none;
    padding-bottom: 0;
    border-top: 1px solid var(--color-white);
  }
}