@use "../mixins/mq";
@use "../_config/colors";
@use "../_base/typography";

.c-cta_contact {
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-40) 0;

  @include mq.mq($from: large) {
    margin: var(--spacing-120) 0;
  }
}

.c-cta-contact__section {
  position: relative;
  margin-right: 0;
  margin-left: var(--spacing-20);
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.2);
  max-width: 68.5rem;

  @include mq.mq($from: large) {
    display: flex;
    flex-direction: row-reverse;
    max-width: 127.5rem;
    margin: 0 auto;
  }

  &--with-bubble {
    margin-top: 5rem;

    @include mq.mq($from: large) {
      margin-top: 0;
    }
  }
}

.c-cta-contact__bubble {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  position: absolute;
  top: - 7.25rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.5rem;
  aspect-ratio: 1;
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--color-primary-1);
  text-align: center;
  color: var(--color-white);
  transform: rotate(-10deg);

  @include mq.mq($from: large) {
    width: 22rem;
    padding: 2rem;
  }
}

.c-cta-contact__content {
  padding: 2rem 0 3.5rem 2rem;

  @include mq.mq($from: medium) {
    padding: 4rem 0 4rem 6rem;
  }

  @include mq.mq($from: large) {
    padding: 8rem 0 8rem 8rem;
  }

  .c-cta-contact__section--no-image & {
    @include mq.mq($from: large) {
      display: grid;
      column-gap: 6rem;
    }
  }
}

.c-cta-contact__text-container {
  margin-bottom: 2rem;
  padding-right: 2rem;

  @include mq.mq($from: medium) {
    padding-right: 6rem;
  }

  .c-cta-contact__section--no-image & {
    @include mq.mq($from: large) {
      grid-column: 2 / span 1;
      padding-right: 8rem;
    }
  }
}

.c-cta-contact__title {
  @extend .font-size-700;
  @extend .font-weight-semibold;
  display: block;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include mq.mq($from: large) {
    max-width: 85%;
  }
}

.c-cta-contact__subtitle {
  @extend .font-size-400;
  display: block;
}

.c-cta-contact__employee-container {
  margin-bottom: 2rem;
  padding-right: 2rem;

  @include mq.mq($from: medium) {
    padding-right: 6rem;
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  .c-cta-contact__section--no-image & {
    @include mq.mq($from: large) {
      grid-column: 2 / span 1;
      padding-right: 8rem;
    }
  }
}

.c-cta-contact__job-title {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  display: block;
  color: var(--color-primary-1);

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.c-cta-contact__contact-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq.mq($from: medium) {
    flex-direction: row;
    align-items: center;
    column-gap: 3rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.c-cta-contact__contact-link {
  @extend .font-size-400;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 2rem;

    @include mq.mq($from: medium) {
      margin-bottom: 0;
    }
  }

  &:hover {
    color: var(--color-primary-1);

    svg {
      fill: var(--color-primary-1);
    }
  }

  .icon {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.c-cta-contact__vertical-line {
  display: none;

  @include mq.mq($from: medium) {
    display: block;
    width: 1px;
    height: 4.5rem;
    background-color: var(--color-secondary-2);
  }
}

.c-cta-contact__cta-button {
  @include mq.mq($from: large) {
    grid-column: 2 / span 1;
  }
}

.c-cta-contact__form {
  padding-top: 2rem;
  padding-right: 3.5rem;

  @include mq.mq($from: medium) {
    padding-right: 6rem;
  }

  .c-form__title {
    display: none;
  }

  .c-cta-contact__section--no-image & {
    @include mq.mq($from: large) {
      grid-column: 2 / span 1;
      padding-right: 8rem;
    }
  }
}