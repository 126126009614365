@use "../mixins/mq";
@use "../utils/visually-hidden";


.header {
  --height-logo: 6rem;
  --scale-logo: 2.5;

  position: fixed;
  z-index: 50;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: var(--headerHeightSmall);
  padding-top: var(--spacing-20);
  transition: transform var(--animationDuration) var(--animationEase);

  @include mq.mq($from: large) {
    height: var(--headerHeight);
    padding-top: var(--spacing-30);
    background-color: transparent;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-grey-100);
    opacity: 1;
    transition: opacity 240ms linear;
  }

  &--scroll {
    transform: translateY(-120%);
  }

  &--top {
    &::before {
      //opacity: 0;
    }
  }
}

.header__home-container {
  @include mq.mq($from: large) {
    display: flex;
    gap: 0 2rem;
    z-index: 1;
  }
}

.header__home-link {
  display: inline-flex;
  margin-left: var(--spacing-20);

  @include mq.mq($from: large) {
    margin-left: var(--spacing-40);
  }

  .icon svg {
    width: auto;
    height: var(--height-logo);

    @include mq.mq($from: large) {
      transition: transform 360ms ease;
    }
  }

  .header--top & {
    .icon svg {
      @include mq.mq($from: large) {
        transform-origin: 0 0;
        transform: scale(var(--scale-logo));
      }
    }
  }
}

.header__home-link-text {
  @extend .visually-hidden;
}

.header__breadcrumb {
  .header--top & {
    transition: transform 360ms ease;

    @include mq.mq($from: large) {
      transform-origin: 50% 0;
      //noinspection CssInvalidFunction
      transform: translateX(calc(var(--height-logo) * var(--scale-logo) / 4 * 3));
    }
  }
}

.header__actions {
  display: flex;
  column-gap: 1rem;

  @include mq.mq($from: large) {
    z-index: 2;
  }
}

.header__cta-button {
  display: none;

  @include mq.mq($from: large) {
    display: block;
  }
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: translate3d(100%, 0, 0);
  transition: transform var(--animationDuration) var(--animationEase), opacity 240ms linear;
  pointer-events: none;

  @include mq.mq($from: medium, $until: large) {
    width: 37.5rem;
  }

  @include mq.mq($from: large) {
    transform: translate3d(0, 0, 0);
    opacity: 0;
    margin-right: calc(var(--scrollbar-width) * -1);
  }

  .header__nav--opened & {
    transform: translate3d(0, 0, 0);
    pointer-events: auto;

    @include mq.mq($from: large) {
      margin-right: 0;
      opacity: 1;
    }
  }

  &-meta {
    margin-bottom: 6rem;

    @include mq.mq($from: large) {
      margin-bottom: 0;
    }
  }
}

.header__picture {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    opacity: .7;
  }
}

.header__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__nav-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  padding-top: 9rem;
  padding-bottom: 5rem;
  overflow-y: scroll;
  overflow-x: hidden;

  @include mq.mq($from: large) {
    flex-direction: row;
    padding: 0;
    overflow-y: hidden;
  }
}

.header__nav-main {
  position: relative;

  @include mq.mq($from: large) {
    flex-grow: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    overflow-y: auto;
  }
}

.header__nav-meta {
  position: relative;

  @include mq.mq($from: large) {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4rem;
    background-color: var(--color-white);
  }
}

.header__hamburger {
  position: relative;
  margin-right: var(--spacing-30);

}
