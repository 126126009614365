$color-black: #000 !default;
$color-grey-200: #BECAD0 !default;
$color-grey-400: #2A3336 !default;
$color-grey-300: #60686D !default;
$color-black: #000000 !default;
$color-grey-100: #F5F6F6 !default;
$color-white: #ffffff !default;
$color-primary-1: #0077BA !default;
$color-primary-2: #0F4155 !default;
$color-secondary-1: #EAF7FA !default;
$color-secondary-2: #394854 !default;
$color-error: #FF004E !default;
$color-success: #4EFE8D !default;
$color-warning: #FFC700 !default;
$color-black-transparent: #000000 !default;
$color-primary-1-transparent: #000000 !default;
