@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;
}

.section--spacing {
	padding-top: var(--spacing-40);
	padding-bottom: var(--spacing-40);

	@include mq.mq($from: large) {
		padding-top: var(--spacing-120);
		padding-bottom: var(--spacing-120);
	}
}


.section--spacing-top {
	padding-top: var(--spacing-40);

	@include mq.mq($from: large) {
		padding-top: var(--spacing-120);
	}
}


.section__title {
	margin-bottom: var(--spacing-40);
}
