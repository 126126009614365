@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
}

.nav__wrap {
  &--child {
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-20);

    @include mq.mq($from: large) {
      padding-top: .6rem;
      white-space: nowrap;
    }
  }
}

.nav__item {
  position: relative;

  &--child {
    width: 100%;
  }
}


.nav__toggle.action {
  position: absolute;
  top: 0.5rem;
  right: calc((1.5rem - .7rem) * -1);
  width: 6rem;
  height: 6rem;

  @include mq.mq($from: large) {
    display: none;
    visibility: hidden;
  }
}

.nav__plus {
  position: relative;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
}

.nav__line {
  position: absolute;
  top: calc(50% - (0.1rem / 2));
  left: 0;
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-white);

  &:last-child {
    top: 0;
    left: calc(50% - (0.1rem / 2));
    display: block;
    width: 0.1rem;
    height: 100%;
    transform-origin: 50% 50%;
    transform: scaleY(1);
    transition: transform 360ms ease, opacity 240ms linear;
  }

  .nav__item--parent-opened & {
    &:last-child {
      opacity: 0;
      transform: scaleY(0);
    }
  }
}

.nav__link {
  font-size: 1.8rem;
  outline: none;
  text-decoration: none;
  color: var(--color-white);

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--color-primary-1);
  }

  &--parent {
    @include mq.mq($from: xlarge) {
      font-size: 2.1rem;
    }
  }

  &--child {
    font-size: 1.5rem;
  }


  .nav--meta-nav & {
    white-space: nowrap;

    @include mq.mq($from: large) {
      color: var(--color-black);
    }
  }
}


