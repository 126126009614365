@use "../mixins/mq";
.c-fullscreen {
	background-color: var(--color-primary-1);

	.section {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 80vh;

		@include mq.mq($from: large) {
			min-height: 65vh;
			padding-left: var(--spacing-100);
		}
	}

	.figure {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	h1 {
		text-transform: uppercase;
	}
}

