@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/vars";
@use "../_config/colors";

.c-usp__section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2.4rem;
  //margin: 4rem 0;
  padding-bottom: 4rem;
  margin-top: 6rem;

  background: linear-gradient(to bottom, #FFFFFF 0 8rem, rgb(colors.$color-primary-1, .1) 8rem 100%);


  @media (min-width: 779px) {
    flex-wrap: nowrap;
    margin-top: 10rem;
  }

  @include mq.mq($from: large) {
    gap: 5rem;
    margin-top: 24rem;
    //margin: 12rem 0;
  }

 /* &::before {
    position: absolute;
    content: "";
    top: 6rem;
    left: 0;
    width: 100%;
    height: calc(100% - 6rem);
    background-color: rgb(colors.$color-primary-1, .1);
  }*/
}


.c-usp__teaser {
  position: relative;
  max-width: 37.5rem;
  padding: 4.5rem 0 6rem;
  background-color: var(--color-secondary-2);
  clip-path: polygon(0% 0%, calc(100% - 4rem) 0%, 100% 4rem, 100% 100%, 4rem 100%, 0% calc(100% - 4rem));

  @media (min-width: 779px) {
    top: 44rem;
    max-width: 60rem;
    transform: translateY(-100%);
  }

  @include mq.mq($from: large) {
    flex-shrink: 0;
    width: min(60%, 74.5rem);
    max-width: none;
    padding: min(6%, 12rem) min(2%, 12rem);
    clip-path: polygon(0% 0%, calc(100% - 8rem) 0%, 100% 8rem, 100% 100%, 8rem 100%, 0% calc(100% - 8rem));
  }
}

.c-usp__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-usp__name {
  @extend .font-size-800;
  position: relative;
  margin: 0 3rem 2rem;
  color: var(--color-white);
}

.c-usp__text {
  position: relative;
  margin: 0 3rem;
  color: var(--color-white);

  a {
    color: inherit;
  }
}

.c-usp__cards {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2rem;
  max-width: 36rem;
  margin: 0 2rem 0 auto;
  padding-left: 2rem;

  @media (min-width: 779px) {
    justify-content: flex-start;

    margin-left: 0;
    padding-left: 0;
  }

  @include mq.mq($from: large) {
    max-width: none;
    gap: 5rem;
  }
}

.c-usp__card {
  flex-basis: 34rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  aspect-ratio: 34/44;
  max-width: 34rem;
  background-color: var(--color-white);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
}

.c-usp__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.c-usp__card-content {
  padding: 3rem 0 0;
}


.c-usp__card-icon {

  padding: 0 3rem;

  .icon {
    width: 4rem;
    height: 4rem;
  }

  .icon-mobile {
    width: 2rem;
    height: 2rem;
    margin-bottom: 2rem;
  }


  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.c-usp__card-eyebrow {
  @extend .font-size-200;
  padding: 0 3rem;
  color: var(--color-grey-300);
}

.c-usp__card-name {
  @extend .font-size-600;
  @extend .font-weight-semibold;
  padding: 0 3rem;
  color: var(--color-secondary-2);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  p {
    @extend .font-size-600;
    @extend .font-weight-semibold;
  }
}

.c-usp__card-text {

  padding: 0 3rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    position: relative;
    z-index: 1;
  }

  p {
    @extend .font-size-300;
  }
}

.c-usp__card-picture-container {
  position: relative;
  font-size: 0;
}

/*.c-usp__card-picture {
  filter: grayscale(0);
  transition: filter 240ms linear;

  .c-usp__card--with-link:hover & {
    filter: grayscale(100%);
  }
}*/

.c-usp__card-picture-icon {
  position: absolute;
  top: -2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid var(--color-primary-1);
  background-color: var(--color-primary-1);
  transition: background-color 240ms linear;

  .icon {
    width: 40%;
    fill: var(--color-white);
    transition: fill 240ms linear;

    .c-usp__card--with-link:hover & {
      fill: var(--color-primary-1);
    }
  }

  .c-usp__card--with-link:hover & {
    background-color: var(--color-white);
  }
}