@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);

	background-color: var(--color-white);
	hyphens: manual;
	font-variant: none;

	//background: white url('../../images/download.svg') 0 0 repeat;
	//background-size: 20px 20px;

	//background-color: black;

	word-break: break-word;

	&.header__nav--opened {
		@include mq.mq($from: large) {
			margin-right: var(--scrollbar-width);
		}
	}
}

main {
	padding-top: var(--headerHeightSmall);

	@include mq.mq($from: large) {
		padding-top: var(--headerHeight);
	}
}
