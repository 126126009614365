@use "../_base/typography";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/svg";
@use "../_config/colors";

.c-facts__wrapper {
  background-color: colors.$color-primary-2;
}

.c-facts__container{
  color: colors.$color-white;
  padding-top: var(--spacing-60);

  @include mq.mq($from: medium) {
    padding: var(--spacing-80);
  }

  @include mq.mq($from: large) {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 2.7rem;
  }

}

.c-facts__title-container{
  padding-bottom: var(--spacing-40);
}

.c-facts__title{
  @extend .font-size-800;
  @extend .font-weight-semibold;
  padding-bottom: var(--spacing-40);
}

.c-facts__subtitle{
  @extend .font-size-600;
}

.c-facts__text{
  @extend .font-size-400;
}

.c-facts__fact-container{

  @include mq.mq($from: medium) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

  }

  @include mq.mq($from: large) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.7rem;
  }
}

.c-facts__fact-item{
  display: grid;
  grid-template-rows: 4rem auto auto;
  grid-template-columns: 1fr;
  grid-row-gap: 4rem;


  @include mq.mq($from: medium) {
    grid-template-rows: 6rem auto auto;
    grid-row-gap: 2rem;
  }

  @include mq.mq($from: large) {
    grid-template-rows: 4rem auto auto;
    grid-row-gap: 4rem;
  }
}

.c-facts__fact-item:nth-of-type(odd) {
  @include mq.mq($from: medium) {
     grid-column-start: 1;
     grid-column-end: 4;
  }

  @include mq.mq($from: large) {
    grid-column: 1;
  }
}

.c-facts__fact-item:nth-of-type(even) {
  @include mq.mq($from: medium) {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  @include mq.mq($from: large) {
    grid-column: 2;
  }

}

.c-facts__fact-item-number-container{
  display: grid;
  grid-template-rows: 2px auto;

  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}


.c-facts__fact-item-number{
  font-size: 150px;
  @extend .font-weight-semibold;
  opacity: 0.1;
  padding-left: 1.4rem;

  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 3;

}

.c-facts__fact-item-line{
  border-left: 1px solid colors.$color-white;
  height: 10rem;

}

.c-facts__fact-item-separator{
  border-left: 1px solid colors.$color-white;
  height: 100px;
  align-self: center;

  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 3;

  @include mq.mq($from: large) {
    height: 16rem;
  }

}

.c-facts__fact-item-text-container{
  padding-left: 2rem;

  grid-column: 1;
  grid-row-start: 2;
  grid-row-end: 3;
}

.c-facts__fact-item-title{
  @extend .font-size-400;
  @extend .font-weight-semibold;

}

.c-facts__fact-item-text{
  @extend .font-size-300;
  height: 100%;
}

