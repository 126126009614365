@use "../mixins/mq";

.nav--footer-nav {

	.nav__wrap {
		flex-direction: column;

		@include mq.mq($from: large) {
			flex-direction: row;
		}
	}

	.nav__item {
		margin-top: var(--spacing-20);

		@include mq.mq($from: large) {
			margin-top: 0;
			margin-left: var(--spacing-80);

		}
	}

	.nav__link {
		color: var(--white);

		.not-touch & {
			&:hover {
				color: var(--color-primary-2);
			}
		}

		&.is-active {
			color: var(--color-primary-2);
		}
	}
}
