@use "../mixins/mq";
@use "../_config/colors";
@use "../_base/typography";

.c-team {
  margin-bottom: var(--spacing-40);

  @include mq.mq($from: large) {
    margin-bottom: var(--spacing-120);
  }
}


.c-team__filter {
  position: relative;
  padding: 1rem;
  margin: 0 .8rem 3rem;
  background-color: var(--color-white);

  @include mq.mq($from: large) {
    margin-bottom: 5rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 20px 0 rgba(26, 25, 25, 0.2);
    pointer-events: none;
    opacity: 0;
    transition: opacity 240ms linear;

    @include mq.mq($from: medium) {
      display: none;
    }
  }

  &--open {
    &::before {
      opacity: 1;
    }
  }
}

.c-team__filter-toggle {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  display: inline-flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0;

  @include mq.mq($from: medium) {
    display: none;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    transition: transform 240ms ease;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--color-primary-1);
    }
  }

  .c-team__filter--open & {
    margin-bottom: 3rem;

    .icon {
      transform: rotate(90deg);
    }
  }
}

.c-team__list {
  display: none;
  padding: 0 1.5rem 2rem;

  @include mq.mq($from: medium) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    max-width: 56rem;
    margin: 0 auto;
    animation: none;
  }

  @include mq.mq($from: large) {
    max-width: 96rem;
  }

  .c-team__filter--open & {
    display: block;
    animation: fadeIn 360ms linear;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-team__item {
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #DDE7EF;

    @include mq.mq($from: medium) {
      border-bottom: none;
    }
  }
}

.c-team__label {
  display: block;
  padding: 0 1rem;

  @include mq.mq($from: medium) {
    padding: 0;
  }
}

.c-team__label-text {
  @extend .font-size-400;
  display: flex;
  align-items: center;
  min-height: 6rem;
  font-size: 2.1rem;

  @include mq.mq($from: medium) {
    min-height: 4rem;
    padding: 0 2rem;
    border: 1px solid var(--color-secondary-2);
    border-radius: 2rem;
    font-size: 1.7rem;
    color: var(--color-secondary-2);
  }

  .c-team__input:checked + & {
    color: var(--color-primary-1);

    @include mq.mq($from: medium) {
      background-color: var(--color-secondary-2);
      color: var(--color-white);
    }
  }
}

.c-team__members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem 3rem;
  padding: 0 .8rem;

  @include mq.mq($from: medium) {
    padding: 0 3rem;
  }

  @include mq.mq($from: large) {
    max-width: 134rem;
    margin: 0 auto;
  }

  @media (min-width: 1440px) {
    gap: 6rem 5rem;
  }
}

.c-team__member {
  width: 100%;
  max-width: 280px;
  transform: translateY(0px);

  &--update {
    animation: flash .25s linear;
  }

  &--hidden {
    display: none;
  }
}

@keyframes flash {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-team__member-name {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  color: var(--color-primary-1);

  &:not(:last-child) {
    margin-bottom: .5rem;
  }
}

.c-team__job-title {
  @extend .font-size-400;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.c-team__contact-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.c-team__contact-link {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    color: var(--color-primary-1);

    svg {
      fill: var(--color-primary-1);
    }
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}