@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/vars";

.c-three_columns__item-link {
	text-decoration: none;
	//color: var(--color-black);
}

.c-three_columns__item-content {
	//padding: 3rem 0 4rem;


	* + * {
		//margin-top: var(--spacing-10);

		@include mq.mq($from: large) {
			margin-top: 0.15rem;
		}
	}

	&:last-child{
		padding-bottom: 4rem;
	}

}

.c-three_columns__item-smallTitle{
	@extend .font-size-200;
	color: var(--color-grey-300);
	padding-top: 2rem;
	font-weight: var(--font-weight-regular);

	@include mq.mq($from: large) {
		padding-top: 3rem;
	}
}

.c-three_columns__item-title {

	&:hover {
		color: var(--color-black)
	}

}

.c-three_columns__item-text{
	//padding: 3rem 0 4rem;
	padding-top: var(--spacing-10);


}


