@use "../mixins/mq";

.hamburger {
  display: block;
  padding: 0;
  border-radius: 50%;

  @include mq.mq($from: large) {
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    width: auto;
    height: 6rem;
    padding: .6rem;
    border-radius: 3rem;
  }

  &::before {
    @include mq.mq($from: large) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 3rem;
      border: 1px solid var(--color-primary-2);
      background-color: var(--color-primary-2);
      transform-origin: 95% 50%;
      transition: transform 240ms ease, background-color var(--animationDuration) var(--animationEase);
    }
  }

  &:hover {
    &::before {
      background-color: var(--color-white);
    }
  }
}

.hamburger__text {
  display: none;
  font-size: var(--font-size-button);
  color: var(--color-white);

  @include mq.mq($from: large) {
    grid-column: 1 / span 1;
    position: relative;
    display: block;
    margin-left: 3rem;
    margin-right: 1.6rem;
    transition: opacity 120ms linear, color var(--animationDuration) var(--animationEase);
  }

  .hamburger:hover & {
    @include mq.mq($from: large) {
      color: var(--color-primary-2);
    }
  }
}

.hamburger__fab {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: var(--color-white);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);

  @include mq.mq($from: large) {
    grid-column: 2 / span 1;
    position: relative;
  }
}

.hamburger__inner {
  position: relative;
  display: block;
  width: 2.9rem;
}

.hamburger__line {
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-primary-2);
  margin: 0.7rem 0;
  transform-origin: 50% 50%;
  transition: transform .3s ease;
}

.hamburger[aria-expanded="true"] {
  pointer-events: none;

  @include mq.mq($from: large) {
    margin-right: var(--scrollbar-width);
  }

  &::before {
    @include mq.mq($from: large) {
      transform: scale(0.1, 0.1);
    }
  }

  .hamburger__text {
    @include mq.mq($from: large) {
      opacity: 0;
    }
  }

  .hamburger__fab {
    pointer-events: auto;
  }

  .hamburger__line {
    transform: scaleX(1.4);

    &:first-child {
      transform: translateY(0.35rem) rotate(-45deg);
    }

    &:last-child {
      transform: translateY(-0.35rem) rotate(45deg);
    }
  }

}
