@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";

a {
	color: var(--color-black);
}

a:hover {
	color: var(--color-primary-1);

}

.action {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);
	cursor: pointer;
}

.action-primary {
	min-height: var(--button-primary-min-height);
	min-width: var(--button-primary-min-width);
	padding: 0 var(--spacing-20);

	font-size: var(--font-size-button);
	@extend .font-weight-700;
	color: var(--color-white);

	border-radius: calc(#{var(--button-primary-min-height)} / 2);
	background-color: var(--color-primary-1);
	border: 1px solid var(--color-primary-1);

	margin-top: var(--spacing-40);

	&--no-margin-top {
		margin-top: 0;
	}

	&.disabled {
		background-color: var(--color-grey-200);
		border-color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-primary-1);
			background-color: var(--color-white);
		}
	}
}

.action-primary--inverted {
	@extend .action-primary;
	color: var(--color-primary-1);
	min-width: var(--button-primary-min-width);

	border-color: var(--color-white);
	background-color: var(--color-white);

	&.disabled {
		color: var(--color-white);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background: none;
		}
	}
}


.action-secondary {
	min-height: var(--button-primary-min-height);
	min-width: var(--button-primary-min-width);
	padding: 0 var(--spacing-20);

	font-size: var(--font-size-button);
	@extend .font-weight-700;
	color: var(--color-primary-1);

	border-radius: calc(#{var(--button-primary-min-height)} / 2);
	border: 1px solid var(--color-primary-1);

	&.disabled {
		color: var(--color-grey-200);
		border-color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background-color: var(--color-primary-1);
		}
	}

	.font-color-white & {
		@extend .action-secondary--inverted;
	}
}

.action-secondary--inverted {
	@extend .action-secondary;
	color: var(--color-white);

	border-color: var(--color-white);
	background: none;

	&.disabled {
		color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-primary-1);
			background-color: var(--color-white);
			border-color: var(--color-white);
		}
	}
}

.action-fab {
	width: 6rem;
	height: 6rem;
	//border-radius: 50%;
	@include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, left, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
	//border: 0.1rem solid var(--color-primary-1);

	.not-touch & {
		&:hover {
			@include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, left, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
		}
	}

	.not-touch .c-three_columns-item:hover & {
		@include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, left, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
	}

	&--inverted {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		border-color: var(--color-white);

		.not-touch & {
			&:hover {
				border-color: var(--color-primary-1);
			}
		}
	}

	&.disabled {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-grey-200);
		border-color: var(--color-grey-200);
	}
}

.action-download {
	@extend .action;
	font-size: var(--font-size-button);
	@extend .font-weight-700;
	color: var(--color-black);

	&:before {
		width: 4rem;
		height: 4rem;
		content: '';
		margin-right: var(--spacing-10);
		@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
	}
}

.action-footer {
	color: var(--color-white);

	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}
