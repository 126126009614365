$fontPath: '../../webfonts/';

@font-face {
	font-family: 'Averta';
	src: url('#{$fontPath}/Averta-Regular/Averta-Regular.woff2') format('woff2'),
	url('#{$fontPath}/Averta-Regular/Averta-Regular.woff') format('woff'),
	url('#{$fontPath}/Averta-Regular/Averta-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Averta';
	src: url('#{$fontPath}/Averta-Semibold/Averta-Semibold.woff2') format('woff2'),
	url('#{$fontPath}/Averta-Semibold/Averta-Semibold.woff') format('woff'),
	url('#{$fontPath}/Averta-Semibold/Averta-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
