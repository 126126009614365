@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/vars";

.breadcrumb {
  display: none;

  @include mq.mq($from: large) {
    display: block;
    padding: 0;
  }
}

.breadcrumb__list {
  display: flex;

  @include mq.mq($from: large) {
    align-items: center;
    min-height: var(--button-primary-min-height);
  }
}

.breadcrumb__item {
  @extend .font-size-200;
  display: flex;
  align-items: center;
  height: 30px;
  overflow: visible;

  &:first-child {
    margin-right: 1.5rem;
  }

  .icon-chevron-right {
    margin: 0 1rem;

    svg {
      fill: var(--color-primary-1);
    }
  }


  &--home {
    margin-left: 0;
  }

  &--last {
    padding-right: var(--spacingRegular);
    color: var(--color-grey-300);
    cursor: default;
  }

}

.breadcrumb__home-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      fill: var(--color-primary-1);
    }
  }

  svg {
    fill: var(--color-grey-300);
  }
}

.breadcrumb__item-title {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: var(--color-grey-300);
  text-decoration: none;
}
