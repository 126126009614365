@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.field__row {

  * {
    font-family: inherit
  }

}


.field-group {
  position: relative;
  margin-top: 5rem;

  &.field-group--select {
    //width: 100%;
    width: calc(50% - #{var(--spacing-30)});

    @include mq.mq($from: large) {
      width: calc(33% - #{var(--spacing-30)});
    }
  }

  &--half {
    width: 100%;

    @include mq.mq($from: large) {
      width: calc(50% - #{var(--spacing-30)});
    }
  }

  &--third {
    width: 100%;

    @include mq.mq($from: large) {
      width: calc(33% - #{var(--spacing-30)});

    }

  }

  &--two-thirds {
    width: 100%;

    @include mq.mq($from: large) {
      width: calc(66% - #{var(--spacing-30)});

    }

  }


  &--large {
    width: 100%;
  }

  &--medium {
    width: 100%;

    @include mq.mq($from: large) {
      @include col.params(4, 6, var(--spacing-60));
    }
  }

  &--small {
    width: 100%;

    @include mq.mq($from: large) {
      @include col.params(2, 6, var(--spacing-60));
    }
  }

  .row & {
    & + .field-group {

      @include mq.mq($from: large) {
        margin-left: var(--spacing-60);
      }
    }
  }

  &--bordered {
    border-bottom: 1px solid var(--color-black);

    &.field-group--has-errors {
      border-bottom: 2px solid var(--color-error);

      &:before {
        position: absolute;
        left: -7px;
        right: -7px;
        bottom: 0;
        height: 100%;
        display: block;
        content: '';
        background-color: #F21C2810;
        z-index: 0;
      }
    }
  }

  &--checkbox {
    margin-top: var(--spacing-60);
  }

  &--html {
    margin-top: var(--spacingWide);
    color: var(--color-black);

    @include mq.mq($from: large) {
      margin-top: 10rem;
    }
  }

  &--block {
    margin-top: var(--spacing-60);
  }
}


.field__input {
  position: relative;
  display: block;
  min-height: 4rem;
  max-width: 100%;
  border: 0;
  width: 100%;
  outline: none;
  background: none;
  @extend .font-size-400;

  &--file {
    width: auto;
    min-height: unset;
  }

  &--select {
    display: block;
    align-items: center;
    font-size: 1.9rem;
    line-height: 3rem;
    color: var(--black);
    border-bottom: 1px solid var(--color-black);
    @include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 12px, 6px, colors.$color-black);

  }
}

.field__label {
  position: relative;
  display: block;
  @extend .font-size-400;


  .field-group--block & {
    position: absolute;
    left: 0;
    top: -4rem;
    width: 100%;
    transition: all var(--animationDuration) var(--animationEase);
    cursor: pointer;
  }

  .field-group--textarea & {
    position: unset;
    left: unset;
    top: unset;
  }

  .field-group--block-animated & {
    font-size: 1.7rem;
    line-height: 2rem;
    color: var(--color-grey-200);
    @extend .font-weight-400;
  }

  &.required {
    &:after {
      content: '*';
    }
  }
}


input[type=checkbox],
.field__input--check,
.field__input--radio {
  @extend .visually-hidden;
}


.field__sub-label {
  position: relative;
  display: block;
  padding-top: 0.8rem;
  margin-top: var(--spacing-20);
  @extend .font-size-400;
  padding-left: 6rem;
  min-height: var(--spacing-40);
  cursor: pointer;

  @include mq.mq($from: large) {
    padding-top: 0.2rem;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 4rem;
    height: 4rem;
    content: '';
    border: 1px solid var(--color-primary-1);
  }

  .field__input--radio + & {
    &:before {
      border-radius: 50%;
    }
  }

  input[type=checkbox]:checked + &,
  .field__input--check:checked + & {
    &:after {
      position: absolute;
      left: .3rem;
      top: .3rem;
      width: 3.6rem;
      height: 3.6rem;
      content: '';
      @include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, 20px, $fillColor: colors.$color-primary-1);
    }

  }

  .field__input--radio:checked + & {
    &:after {
      position: absolute;
      left: .4rem;
      top: .4rem;
      display: block;
      content: '';
      width: 3.4rem;
      height: 3.4rem;
      background-color: var(--color-primary-1);
      border-radius: 50%;
    }
  }

  &--inline {
    display: inline-flex;
    margin-right: var(--spacing-40);

    .field__wrap--grid & {
      margin-right: 0;
      margin-top: 0;
    }
  }
}

.field__input--textarea {
  min-height: 12rem;
}

.field__errors {
  position: absolute;
  top: 100%;
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  @extend .font-weight-500;
  color: var(--color-error);

  :is(.field-group--checkbox_group) + & {
    position: relative;
    top: auto;
    margin-top: var(--spacing-10);
  }
}

.field-group--submit {
  .action + .action {
    margin-left: var(--spacing-20);
  }
}

.field__wrap--grid {
  display: grid;
  margin-top: 2rem;
  gap: 2rem;

  @include mq.mq($from: large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.field__input--file_drag_and_drop {
  margin-top: 2rem;
  border: 1px dashed var(--color-black);
  border-radius: 5px;
}