@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "svg";
@use "vars";

//@use "../mixins/background-svg" as backSvg;

// H6
.font-size-200 {
  font-size: var(--font-size-200-s);
  font-weight: var(--font-weight-semibold);
  line-height: 1.69;
  letter-spacing: normal;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-200);
    font-weight: var(--font-weight-regular);
    line-height: 1.69;
  }
}

// H5
.font-size-300 {
  font-size: var(--font-size-300-s);
  //font-weight: var(--font-weight-semibold);
  font-weight: var(--font-weight-regular);
  line-height: 1.67;
  letter-spacing: 0.05em;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-regular);
    line-height: 1.59;
  }
}

// Body
.font-size-400 {
  font-size: var(--font-size-400-s);
  font-weight: var(--font-weight-regular);
  line-height: 1.56;
  //letter-spacing: 0.75px;
  letter-spacing: 0.03em;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-400);
    font-weight: var(--font-weight-regular);
    line-height: 1.71;
    letter-spacing: 0.04em;
  }

}


// H4
.font-size-600 {
  font-size: var(--font-size-600-s);
  font-weight: var(--font-weight-semibold);
  line-height: 1.52;
  letter-spacing: 0.03em;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-600);
    line-height: 1.52;
    font-weight: var(--font-weight-regular);

  }
}

// H3
.font-size-700 {
  font-size: var(--font-size-700-s);
  font-weight: var(--font-weight-semibold);
  line-height: 1.52;
  letter-spacing: 0.03em;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-700);
    line-height: 1.41;
    font-weight: var(--font-weight-regular);
    letter-spacing: 0.03em;
  }
}

// H2
.font-size-800 {
  font-size: var(--font-size-800-s);
  font-weight: var(--font-weight-semibold);
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: none;

  @include mq.mq($from: large) {
    font-size: var(--font-size-800);
    //font-weight: var(--font-weight-regular);
    font-weight: var(--font-weight-semibold);
    line-height: 1.38;
    letter-spacing: normal;
    text-transform: none;
  }
}

// H1
.font-size-900 {
  font-size: var(--font-size-900-s);
  font-weight: var(--font-weight-semibold);
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: none;


  @include mq.mq($from: large) {
    font-size: var(--font-size-900);
    line-height: 1.23;
  }
}

// Footer
.font-size-footer {
  font-size: 1.3rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.69;
  letter-spacing: normal;
  text-transform: none;

  @include mq.mq($from: xsmall) {
    font-size: 1.5rem;
  }

  @include mq.mq($from: largeFooter) {
    font-size: 1.7rem;
  }

  @include mq.mq($from: large) {
    line-height: 1.59;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-regular {
  font-weight: normal
}

/*
strong,
b,
.font-weight-700 {
  font-weight: 700;
}
 */

strong,
b,
{
  @extend .font-weight-semibold;
}


.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}


p {
  margin-top: 2rem;
  @extend .font-size-400;
  //@extend .font-weight-regular;

  &:first-child,
  &:only-child {
    margin-top: 0;
  }
}

h1, .h1 {
  @extend .font-size-900;
  //@extend .font-weight-semibold;
}

h2, .h2 {
  //@extend .font-weight-regular;
  @extend .font-size-800;
}

h3, .h3 {
  @extend .font-weight-regular;
  @extend .font-size-700;
}

h4, .h4 {
  @extend .font-weight-regular;
  @extend .font-size-600
}

h5, .h5 {
  @extend .font-size-300
}

h6, .h6 {
  @extend .font-size-200
}


.font-color-white {
  color: var(--color-white);
}

blockquote {
  margin-bottom: var(--spacing-50);
  @extend .font-size-700;
  @extend .font-weight-semibold;
  quotes: "«" "»" "," "’";
  color: var(--color-grey-400);
  border-left: 1px solid var(--color-grey-400);
  padding-left: 18px;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include mq.mq($from: large) {

    margin-bottom: var(--spacing-120);
    padding-left: 50px;
  }
}

.styled {

  .font-size-600 + *,
  .font-size-400 + * {
    margin-top: var(--spacing-20);
  }

  .font-size-700 + *,
  .font-size-800 + * {
    margin-top: 3rem;

    @include mq.mq($from: large) {
      margin-top: var(--spacing-40);
    }
  }

  .font-size-900 + * {
    margin-top: var(--spacing-40);
  }

  figcaption {
    padding-top: var(--spacing-20);
    text-align: left !important;
    @extend .font-size-300;
  }

  .media:not(:first-child),
  .iframe-wrapper + *,
  figure:not(.iframe__poster) + * {
    margin-top: var(--spacing-40);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-80);
    }
  }

  * + blockquote {
    margin-top: var(--spacing-50);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-120);
    }
  }

  * + ul,
  * + ol {
    margin-top: var(--spacing-40);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-60);
    }
  }

  ul, ol {
    @extend .font-size-400;
    @extend .font-weight-regular;

    & + * {
      margin-top: var(--spacing-40);

      @include mq.mq($from: large) {
        margin-top: var(--spacing-60);
      }
    }

    li {
      position: relative;
      padding-left: 3.5rem;
      counter-increment: counter-inc;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }

    }
  }

  ul {
    li:before {
      position: absolute;
      left: 0;
      top: 13px;
      width: 16px;
      height: 2px;
      content: '';
      @include background-svg.params(svg.$svg-minus, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);

      @include mq.mq($from: large) {
        top: 17px;
      }
    }
  }

  ol {
    counter-reset: counter-inc;

    li:before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-flex;
      content: counter(counter-inc) ". ";
      width: 2.5rem;
      color: var(--color-primary-1);
      font-size: inherit;
      @extend .font-weight-semibold;
    }
  }

  .checklist li {


    &:before {
      display: block;
      left: 0;
      top: 4px;
      width: 22px;
      height: 22px;
      content: '';
      @include background-svg.params(svg.$svg-check-circle, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);

      @include mq.mq($from: large) {
        top: 8px;
      }

    }
  }

}
