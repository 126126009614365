@use "../_base/typography";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/svg";
@use "../_config/colors";

.c-list_two_columns__title {
  @extend .font-size-700;
  @extend .font-weight-semibold;

  @include mq.mq($from: medium) {
    padding-bottom: var(--spacing-20);
  }

  @include mq.mq($from: large) {
    padding-bottom: var(--spacing-80);
  }
}

.c-list_two_columns__grid {
  display: grid;
  row-gap: 3rem;


  @include mq.mq($from: medium) {
    padding-left: 103px;
  }

  @include mq.mq($from: large) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
  }

}

.c-list_two_columns__item-intro-text {
  margin-bottom: var(--spacing-40);

}



.c-list_two_columns__item {
  display: grid;
  grid-template-columns: 1fr 4fr;

  @include mq.mq($from: medium) {
    min-height: 4rem;
    grid-template-columns: 0.5fr 4fr;
  }

  @include mq.mq($from: large) {
    min-height: 8rem;
    grid-template-columns: 1fr 4fr;
  }
}


.c-list_two_columns__item-icon-bubble {
  height: 4rem;
  width: 4rem;
  background-color: colors.$color-grey-100;
  border-radius: 50%;
  display: inline-block;


  @include mq.mq($from: large) {
    height: 8rem;
    width: 8rem;
  }

}

.c-list_two_columns__item-icon {
  padding: 1rem;

  &:before {
    display: block;
    width: 2rem;
    height: 2rem;
    content: '';
    @include background-svg.params(svg.$svg-lightbulb, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
  }

  @include mq.mq($from: large) {
    padding: 2rem;

    &:before {
      display: block;
      width: 4rem;
      height: 4rem;
      content: '';
      @include background-svg.params(svg.$svg-lightbulb, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
    }
  }
}

.c-list_two_columns__item-content {

  @include mq.mq($from: large) {
    min-height: 8rem;
  }
}


.c-list_two_columns__item-title {
  @extend .font-weight-semibold;
  align-items: center;
  display: flex;

  @include mq.mq($from: large) {
    min-height: 8rem;
  }
}

.c-list_two_columns__item-text {
  @extend .font-size-300;
}

