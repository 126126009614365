@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/vars";
@use "../_config/colors";

.c-heading {
  padding: 3rem 0;
  background-color: var(--color-grey-100);

  @include mq.mq($from: medium) {
    padding: 4rem 0;
  }

}

.c-heading__section {
  max-width: 56rem;
  margin: 0 auto;
  padding: 0 var(--spacing-20);

  @include mq.mq($from: large) {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 60rem) minmax(auto, 74.5rem) 1fr;
    gap: 4rem;
    max-width: none;
    padding: 0 var(--spacing-40);
  }

  @include mq.mq($from: 1920px) {
    grid-template-columns: 1fr minmax(auto, 60rem) minmax(auto, 74.5rem) minmax(25rem, 1fr);
  }
}

.c-heading__title {
  @extend .font-size-900;
  margin-bottom: 3rem;

  @include mq.mq($from: medium) {
    margin-bottom: 4rem;
  }

  @include mq.mq($from: large) {
    grid-column: 2 / span 1;
    align-self: end;
    margin-bottom: 0;
  }
}

.c-heading__text {
  margin-bottom: 4rem;

  @include mq.mq($from: medium) {
    margin-bottom: 5rem;
  }

  @include mq.mq($from: large) {
    grid-column: 2 / span 1;
    align-self: start;
    margin-bottom: 0;
  }
}

.c-heading__picture-container {
  position: relative;

  @include mq.mq($from: large) {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
    align-self: end;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;

    @include mq.mq($from: medium) {
      margin-bottom: 4rem;
    }

    @include mq.mq($from: large) {
      margin-bottom: 0;
    }
  }
}

.c-heading__image {
  clip-path: polygon(0% 0%, calc(100% - 4rem) 0%, 100% 4rem, 100% 100%, 4rem 100%, 0% calc(100% - 4rem));

  @include mq.mq($from: large) {
    clip-path: polygon(0% 0%, calc(100% - 8rem) 0%, 100% 8rem, 100% 100%, 8rem 100%, 0% calc(100% - 8rem));
  }

  @include mq.mq($from: 1920px) {
    clip-path: polygon(0% 0%, calc(100% - 12rem) 0%, 100% 12rem, 100% 100%, 12rem 100%, 0% calc(100% - 12rem));
  }
}

.c-heading__icon-label {
  position: absolute;
  top: -3rem;
  right: 1rem;
  width: 8rem;
  height: 8rem;

  @include mq.mq($from: medium) {
    top: -1rem;
    right: -5rem;
    width: 10rem;
    height: 10rem;
  }

  @include mq.mq($from: large) {
    top: -2rem;
    right: -7rem;
    width: 14rem;
    height: 14rem;
  }

  @include mq.mq($from: 1920px) {
    top: 3rem;
    right: -17rem;
    width: 14rem;
    height: 14rem;
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}

.c-heading__navigation {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq.mq($from: large) {
    grid-column: 2 / span 2;
    grid-row: 3 / span 1;
  }

  @include mq.mq($from: 1920px) {
    grid-column: 4 / span 1;
    grid-row: 1 / span 2;
    align-self: end;
  }
}