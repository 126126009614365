@use "../mixins/mq";
@use "../_base/typography";
@use "../_base/vars";
@use "../_config/colors";

.c-faq {
  padding: 3rem 0;

  @include mq.mq($from: medium) {
    padding: 4rem 0;
  }
}

.c-faq__section {
  max-width: 56rem;
  margin: 0 auto;
  padding: 0 var(--spacing-20);

  @include mq.mq($from: medium) {
    max-width: 78rem;
  }

  @include mq.mq($from: large) {
    max-width: 152rem;
  }
}

.c-faq__header {
  margin-bottom: 4rem;

  @include mq.mq($from: medium) {
    margin-bottom: 6rem;
  }

  @include mq.mq($from: large) {
    margin-bottom: 8rem;
  }
}

.c-faq__title {
  @extend .font-size-900;

  @include mq.mq($from: medium) {
    text-align: center;
  }
}

.c-faq__subtitle {
  @extend .font-size-600;
  margin-top: 1.5rem;

  @include mq.mq($from: medium) {
    text-align: center;
  }
}

.c-faq__categories {
  display: grid;
  gap: 2rem;
  margin-bottom: 5rem;

  @include mq.mq($from: medium) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-bottom: 6rem;
  }

  @include mq.mq($from: large) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-bottom: 8rem;
  }
}

.c-faq__category {
  filter: drop-shadow(0 2px 16px rgba(0, 0, 0, 0.2));

  @include mq.mq($from: medium) {
    max-width: 35.5rem;
  }

  @include mq.mq($from: large) {
    max-width: none;
  }
}

.c-faq__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  aspect-ratio: 4/3;
  background-color: var(--color-white);
  clip-path: polygon(0% 0%, calc(100% - 6rem) 0%, 100% 6rem, 100% 100%, 6rem 100%, 0% calc(100% - 6rem));
  text-decoration: none;
}

.c-faq__icon {
  width: 6rem;
  height: 6rem;

  .icon, svg {
    width: 100%;
    height: 100%;
    fill: var(--color-primary-1)
  }
}

.c-faq__label {
  @extend .font-size-700;
}

.c-faq__groups {
  margin: 0 auto;

  @include mq.mq($from: medium) {
    max-width: 56rem;
  }

  @include mq.mq($from: large) {
    max-width: 100rem;
  }
}

.c-faq__group {
  &:not(:last-child) {
    margin-bottom: 4rem;

    @include mq.mq($from: medium) {
      margin-bottom: 5.5rem;
    }

    @include mq.mq($from: large) {
      margin-bottom: 6rem;
    }
  }
}

.c-faq__group-title {
  @extend .font-size-600;
  margin-bottom: 1rem;
}

.c-faq__accordion-item {
  background-color: var(--color-grey-100);
  padding: 1.5rem 2.5rem 1.5rem 4.5rem;

  @include mq.mq($from: large) {
    padding: 1.5rem 7rem;
  }

  &:not(:last-child) {
    margin-bottom: .6rem;
  }
}

.c-faq__accordion-summary {
  @extend .font-size-400;
  @extend .font-weight-semibold;
  position: relative;
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display:none;
  }
}

.c-faq__plus {
  position: absolute;
  top: calc(50% - 1rem);
  left: -3.5rem;
  display: block;
  width: 2rem;
  height: 2rem;

  @include mq.mq($from: large) {
    left: -5rem;
  }
}

.c-faq__line {
  position: absolute;
  display: block;
  background-color: var(--color-black);

  &--first {
    top: calc(50% - (0.15rem / 2));
    left: 0;
    width: 100%;
    height: 0.15rem;
  }

  &--second {
    top: 0;
    left: calc(50% - (0.15rem / 2));
    display: block;
    width: 0.15rem;
    height: 100%;
    transform-origin: 50% 50%;
    transform: scaleY(1);
    transition: transform 360ms ease, opacity 240ms linear;
  }

  .c-faq__accordion-item[open] & {
    &--second {
      opacity: 0;
      transform: scaleY(0);
    }
  }
}

.c-faq__accordion-content {
  padding: 2rem 0;
  opacity: 0;
  transition: opacity 360ms linear;

  @include mq.mq($from: large) {
    padding: 2rem 0 9rem;
  }

  .c-faq__accordion-item[open] & {
    opacity: 1;
  }
}