@use "../_config/colors";
@use "../_config/fontSize-desktop";
@use "../_config/fontSize-mobile";
@use "../_config/spacing";
@use "../../webfonts/stylesheet";


:root {
  --container-wdith: 160rem;

  --globalFontSize: #{fontSize-desktop.$font-size-400};
  --globalLineHeight: 1.5;

  --color-black: #{colors.$color-black};
  --color-grey-400: #{colors.$color-grey-400};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-100: #{colors.$color-grey-100};
  --color-white: #{colors.$color-white};
  --color-primary-1: #{colors.$color-primary-1};
  --color-primary-2: #{colors.$color-primary-2};
  --color-secondary-1: #{colors.$color-secondary-1};
  --color-secondary-2: #{colors.$color-secondary-2};
  --color-error: #{colors.$color-error};
  --color-success: #{colors.$color-success};
  --color-warning: #{colors.$color-warning};
  --color-black-transparent: #{colors.$color-black-transparent};
  --color-primary-1-transparent: #{colors.$color-primary-1-transparent};

  --spacing-200: #{spacing.$spacing-200};
  --spacing-140: #{spacing.$spacing-140};
  --spacing-120: #{spacing.$spacing-120};
  --spacing-100: #{spacing.$spacing-100};
  --spacing-80: #{spacing.$spacing-80};
  --spacing-60: #{spacing.$spacing-60};
  --spacing-50: #{spacing.$spacing-50};
  --spacing-40: #{spacing.$spacing-40};
  --spacing-30: #{spacing.$spacing-30};
  --spacing-20: #{spacing.$spacing-20};
  --spacing-10: #{spacing.$spacing-10};


  --font-size-200-s: calc(#{fontSize-mobile.$font-size-200-s} * 1.6);
  --font-size-300-s: calc(#{fontSize-mobile.$font-size-300-s} * 1.6);
  --font-size-400-s: calc(#{fontSize-mobile.$font-size-400-s} * 1.6);
  --font-size-600-s: calc(#{fontSize-mobile.$font-size-600-s} * 1.6);
  --font-size-700-s: calc(#{fontSize-mobile.$font-size-700-s} * 1.6);
  --font-size-800-s: calc(#{fontSize-mobile.$font-size-800-s} * 1.6);
  --font-size-900-s: calc(#{fontSize-mobile.$font-size-900-s} * 1.6);

  --font-size-200: calc(#{fontSize-desktop.$font-size-200} * 1.6);
  --font-size-300: calc(#{fontSize-desktop.$font-size-300} * 1.6);
  --font-size-400: calc(#{fontSize-desktop.$font-size-400} * 1.6);
  --font-size-600: calc(#{fontSize-desktop.$font-size-600} * 1.6);
  --font-size-700: calc(#{fontSize-desktop.$font-size-700} * 1.6);
  --font-size-800: calc(#{fontSize-desktop.$font-size-800} * 1.6);
  --font-size-900: calc(#{fontSize-desktop.$font-size-900} * 1.6);

  /*
  --font-size-200-s: #{fontSize-mobile.$font-size-200-s};
  --font-size-300-s: #{fontSize-mobile.$font-size-300-s};
  --font-size-400-s: #{fontSize-mobile.$font-size-400-s};
  --font-size-600-s: #{fontSize-mobile.$font-size-600-s};
  --font-size-700-s: #{fontSize-mobile.$font-size-700-s};
  --font-size-800-s: #{fontSize-mobile.$font-size-800-s};
  --font-size-900-s: #{fontSize-mobile.$font-size-900-s};

  --font-size-200: #{fontSize-desktop.$font-size-200};
  --font-size-300: #{fontSize-desktop.$font-size-300};
  --font-size-400: #{fontSize-desktop.$font-size-400};
  --font-size-600: #{fontSize-desktop.$font-size-600};
  --font-size-700: #{fontSize-desktop.$font-size-700};
  --font-size-800: #{fontSize-desktop.$font-size-800};
  --font-size-900: #{fontSize-desktop.$font-size-900};
   */

  --font-weight-regular: normal;
  --font-weight-semibold: 600;

  --font-size-button: var(--font-size-300);

  --button-primary-min-height: 6rem;
  --button-primary-min-width: 20rem;

  --bodyFontFamily: "Averta", Helvetica, Arial, sans-serif;

  --headerHeightSmall: 10rem;
  --headerHeight: 13rem;

  --hamburgerLineHeight: 2px;
  --hamburgerWidth: 48px;
  --hamburgerSpacing: 12px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25, .46, .45, .75);

  --hover-h-gap: .8rem;
}

$bodyFontFamily: "Averta", Helvetica, Arial, sans-serif;


