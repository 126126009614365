@use "../_base/typography";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.nav--heading-nav {
  width: 100%;

  .nav__wrap--parent {
    @include mq.mq($from: medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      column-gap: 1.3rem;
      row-gap: 2.5rem;
    }

    @include mq.mq($from: large) {
      column-gap: 2.7rem;
    }
  }

  .nav__wrap--child {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding-bottom: 0;
  }

  .nav__item--parent {
    position: relative;
    margin: 0 calc(var(--spacing-20) - var(--hover-h-gap));

    @include mq.mq($from: medium) {
      flex-shrink: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: calc((var(--spacing-20) - var(--hover-h-gap)) * -1);
      width: 1px;
      height: calc(100%);
      background-color: var(--color-grey-200);
    }

    &:not(:last-child) {
      margin-bottom: 3rem;

      @include mq.mq($from: medium) {
        margin-bottom: 0;
      }
    }
  }

  .nav__item--child {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--hover-h-gap);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: calc((var(--spacing-20) - var(--hover-h-gap)) * -1);
      width: 4px;
      height: 100%;
      background-color: var(--color-grey-200);
      opacity: 0;
      transition: opacity 240ms linear;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  .nav__summary {
    position: relative;
    margin: 0 var(--hover-h-gap);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: calc((var(--spacing-20)) * -1);
      width: 4px;
      height: 100%;
      background-color: var(--color-grey-200);
      opacity: 0;
      transition: opacity 240ms linear;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    .nav__text {
      padding-right: 2.6rem;
    }
  }

  .nav__link {
    @extend .font-weight-700;
    width: 100%;
    color: var(--color-secondary-2);
  }

  .nav__link--parent {
    display: inline-flex;
    align-items: center;
    padding: 1rem 0;

    &:hover {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, right, center, no-repeat, 1.6rem, 1.6rem, colors.$color-secondary-2);
      color: var(--color-primary-1);
    }
  }

  .nav__link--child {
    padding: 0.5rem 0;
    color: var(--color-grey-300);

    &:hover {
      color: var(--color-primary-1);
    }
  }
}
