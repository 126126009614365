@use "../_base/typography";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.nav--main-nav {
  @include mq.mq($from: large) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .nav__wrap--parent {
    @include mq.mq($from: large) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 var(--spacing-40);
      max-width: min(90%, 128rem);
      max-height: 100%;
    }

    @media (min-width: 100em) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .nav__wrap--child {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;

    @include mq.mq($from: large) {
      row-gap: 1.2rem;
    }
  }

  .nav__item--parent {
    max-height: 7rem;
    margin: 0 calc(var(--spacing-20) - var(--hover-h-gap));
    transition: max-height 480ms ease;
    overflow-y: hidden;

    @include mq.mq($from: large) {
      max-height: none;
      min-height: min-content;
    }
  }

  .nav__item--child {
    position: relative;
    display: flex;
    align-items: center;
    min-height: calc(4rem - 1.2rem);
    padding: 0 var(--hover-h-gap);

    &:hover {
      &::before {
        opacity: 0.3;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -0.6rem;
      left: 0;
      width: 100%;
      height: calc(100% + 1.2rem);
      background-color: var(--color-primary-1);
      opacity: 0;
      transition: opacity 240ms linear;
    }
  }

  .nav__item--parent-opened {
    max-height: 800px;

    @include mq.mq($from: large) {
      max-height: none;
    }
  }

  .nav__summary {
    position: relative;
    margin: 0 var(--hover-h-gap);

    &::before {
      content: "";
      position: absolute;
      bottom: 0.8rem;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-grey-300);

      @include mq.mq($from: large) {
        bottom: 0;
      }
    }
  }

  .nav__link {
    @extend .font-weight-700;
  }

  .nav__link--parent {
    display: inline-flex;
    height: 7rem;
    align-items: center;

    &:hover {
      color: var(--color-primary-1);
    }

    @include mq.mq($from: large) {
      width: 100%;
      height: 4rem;

      &:hover {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, right, center, no-repeat, 1.6rem, 1.6rem, colors.$color-primary-1);
      }
    }

    @include mq.mq($from: xlarge) {
      height: 5rem;
    }
  }

  .nav__link--child {
    position: relative;

    @include mq.mq($from: large) {
      width: 100%;
      display: inline-flex;
      min-height: 2.8rem;
      align-items: center;
    }

    &:hover {
      color: var(--color-white);
    }
  }
}
