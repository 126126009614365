@use "_common";
@use "atoms";
@use "molecules";
@use "organisms";

.block {
	padding: var(--spacing-80);
}

.block--inverted {
	background-color: #89969F;
}

/*
#container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
}

body {
	overflow: hidden;
}
*/