@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

video,
img {
	width: 100%;
	height: auto;
	object-fit: cover;
	//object-position: top center;
}


.figure {
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;

	svg {
		width: 100%;
		max-width: 100%;
		height: auto;
	}


	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
//		background: linear-gradient(45deg, transparent 90%, var(--color-white) 90%), linear-gradient(225deg, transparent 90%, var(--color-white) 90%);
	}

	picture,
	img,
	video {
		position: absolute;
		object-fit: cover;
		//object-position: top center;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
	}

	&-gallery {
		height: 100%;

		picture,
		img {
			position: unset;
			left: unset;
			top: unset;
		}

		picture {
			display: flex;
			height: 100%;
			align-items: center;
		}

		img {
			max-width: 100vw;
			width: 100%;
			height: auto;

			@include mq.mq($from: 800px) {
				height: 100%;
			}
		}
	}

	&-three {
		aspect-ratio: 483/375;
	}

	&-three:after {
		background: none !important;
	}

	&-two {
		aspect-ratio: 1/1;
	}

	&-two:after {
		background: linear-gradient(45deg, transparent 90%, var(--color-white) 90%), linear-gradient(225deg, transparent 90%, var(--color-white) 90%);
	}

	&-fullscreen:after,
	&-cta:after {
		background-color: color.adjust(colors.$color-black, $alpha: -0.55);
	}
}

/*.figure-slider--small {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 54.91%;
	}
}

.figure-slider--tall {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 77.56%;
	}
}*/

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}

.figure-employee {
	aspect-ratio: 7/9;
	width: 160px;
	margin-bottom: 2rem;
	clip-path: polygon(0% 0%, calc(100% - 2.4rem) 0%, 100% 2.4rem, 100% 100%, 2.4rem 100%, 0% calc(100% - 2.4rem));

	.c-cta-contact__section--no-image & {
		@include mq.mq($from: large) {
			grid-column: 1 / span 1;
		}
	}
}

.figure-contact {
	position: relative;
	aspect-ratio: 685/267;
	z-index: -1;

	@include mq.mq($from: large) {
		aspect-ratio: unset;
		flex-shrink: 0;
		flex-basis: 48rem;
		width: 40rem;
	}
}


.figure-team {
	width: 100%;
	aspect-ratio: 7/9;
	margin-bottom: 2rem;

	img {
		clip-path: polygon(0% 0%, calc(100% - 3.5rem) 0%, 100% 3.5rem, 100% 100%, 3.5rem 100%, 0% calc(100% - 3.5rem));
	}
}

.figure-usp {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(57, 72, 84, 0.6);
	}
}

.figure-uspCard {
	aspect-ratio: 170/93;
	filter: grayscale(0);
	transition: filter 240ms linear;

	.c-usp__card--with-link:hover & {
		filter: grayscale(100%);
	}
}