@use "../mixins/mq";
@use "../atoms/grid";

.c-cta {

	.container:before,
	.figure {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 566px;
		@extend .container;
		transform: translate3d(-50%, -50%, 0);

		@include mq.mq($from: medium) {
			max-width: 68rem;
		}

		@include mq.mq($from: large) {
			max-width: none;
			height: 575px;
		}
	}

	.container {
		padding-top: var(--spacing-40);
		padding-bottom: var(--spacing-40);

		&:before {
			display: block;
			height: 100%;
			content: '';
			background-color: var(--color-primary-1);
		}
		@include mq.mq($from: medium) {
			max-width: 68rem;
		}

		@include mq.mq($from: large) {
			max-width: none;
			padding-top: var(--spacing-120);
			padding-bottom: var(--spacing-120);
		}
	}

	.icon {
		width: 4rem;
		margin-bottom: var(--spacing-20);
	}
}

.c-cta-text{

	blockquote {
		color: var(--color-white);
		border-left: none;
		padding-left: 0;
	}
}
