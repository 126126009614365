@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";
@use "../atoms/grid";

.footer__wrapper {
  @extend .middle;

  @include mq.mq($from: medium) {
    max-width: 56rem;
  }

  @include mq.mq($from: large) {
    max-width: none;
  }

}

.footer__width {
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-2);
}

.footer {
  color: var(--color-white);
  background-color: var(--color-primary-2);


  max-width: var(--container-wdith);
  margin-left: auto;
  margin-right: auto;


  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
  padding-bottom: var(--spacing-40);

  @include mq.mq($from: medium) {
    padding-left: var(--spacing-40);
    padding-right: var(--spacing-40);
    padding-bottom: var(--spacing-80);
  }

  .action-secondary {
    @extend .action-secondary--inverted;
  }
}

.footer__top {
  padding-top: var(--spacing-40);
  padding-bottom: var(--spacing-20);
  background-color: var(--color-primary-2);

  display: grid;

  @include mq.mq($from: large) {
    padding-top: var(--spacing-60);
    //padding-bottom: var(--spacing-60);
  }
}


.footer__top-left {
  padding-bottom: 7rem;

  @include mq.mq($from: large) {
    padding-bottom: 0;
  }
}

.footer__top-right {
  display: flex;
  flex-wrap: wrap;

  @include mq.mq($from: large) {
    margin-top: var(--spacing-20);
  }
}


.footer__address-container {
  margin-bottom: var(--spacing-30);
  @extend .font-size-300;
  @extend .font-weight-regular;


  @include mq.mq($from: largeFooter) {
    margin-bottom: 0;
    display: flex;
    flex-grow: 1;
    column-gap: var(--spacing-20);
    flex-shrink: 0;
    align-items: center;

    border: 1px var(--color-white);
    border-style: solid none;
  }
}

.footer__address {
  @include mq.mq($from: largeFooter) {
    display: flex;
    column-gap: var(--spacing-20);
    align-items: center;
  }
}

.footer__address-company {
}

.footer__address-postal {
}

.footer__contact {
  @include mq.mq($from: largeFooter) {
    display: flex;
    column-gap: var(--spacing-20);
    align-items: center;
  }

}


.footer__contact-phone {
  padding-top: var(--spacing-20);
  padding-bottom: var(--spacing-20);

  @include mq.mq($from: largeFooter) {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }

}

.footer__contact-mail {
}

.footer__bottom-container {

  @include mq.mq($from: largeFooter) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}


.footer__bottom {
  @extend .font-size-footer;
  background-color: var(--color-primary-2);

  display: flex;
  justify-content: space-between;

  padding-top: var(--spacing-10);
  padding-bottom: var(--spacing-10);

  border: 1px var(--color-white);
  border-style: solid none;

  @include mq.mq($from: largeFooter) {
    //@extend .font-size-300;
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }
}


.footer__bottom-separator {
  border-right: 1px solid var(--color-white);
  margin: 0 var(--spacing-20);

  &--first {
    display: none;

    @include mq.mq($from: largeFooter) {
      display: block;
    }
  }

  @include mq.mq($from: large) {
    transform: scaleY(1.7);
  }
}

.footer__bottom-nav {
  @extend .font-size-footer;
  color: var(--color-white);
}