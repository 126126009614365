@use "../mixins/mq";
@use "../_base/typography";

.nav--meta-nav {
  @extend .font-size-300;
  margin-top: 2rem;

  @include mq.mq($from: large) {
    margin-top: 0;
  }

  .nav__item--parent {
    width: 100%;
    margin: var(--spacing-10) var(--spacing-20);

    @include mq.mq($from: large) {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .nav__link {
    .not-touch & {
      &:hover {
        color: var(--color-primary-1);
      }
    }
  }
}
